import React from "react";
import "../Styles/Footer.css";
import FooterLogo from "../Assets/footer-logo.svg";
import FacebookLogo from "../Assets/facebook.svg";
import InstagramLogo from "../Assets/instagram.svg";
import TwitterLogo from "../Assets/twitter.svg";
import { Link } from "react-router-dom";

type FooterProps = {
  backgroundColor?: string;
  display?: string;
};

function Footer(props: FooterProps) {
  return (
    <div className="footer" style={{
      backgroundColor: props.backgroundColor ? props.backgroundColor : "white",
      display: props.display ? props.display : "flex",
    }}>
      <a href="https://thevarsity.ca">
        <img className="footer-logo" src={FooterLogo} alt="logo" />
      </a>
      <div className="footer-links">
        <div>
          <Link to="/credits" className="footer-link">
            Credits
          </Link>
        </div>
        <p className="footer-divider">|</p>
        <div>
          <Link to="/joinus" className="footer-link">
            Join Us!
          </Link>
        </div>
      </div>
      <div className="social-links">
        <a href="https://twitter.com/theVarsity">
          <img src={TwitterLogo} alt="twitter" />
        </a>
        <a href="https://www.facebook.com/thevarsitynewspaper">
          <img src={FacebookLogo} alt="facebook" />
        </a>
        <a href="https://www.instagram.com/the.varsity/">
          <img src={InstagramLogo} alt="instagram" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
