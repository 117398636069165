import React from 'react';
import { useContext } from 'react';

type FooterType = {
    articleType: string;
    setArticleType: (articleType: string) => void;
}

const FooterContext = React.createContext<FooterType>({
    articleType: '',
    setArticleType: () => {},
});

const useFooterContext = () => useContext(FooterContext);

export { FooterContext, useFooterContext };