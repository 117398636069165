import React, { useEffect } from "react";
import "../Styles/LetterFromTheEditor.css";
import { useFooterContext } from "../Contexts/FooterContext";

const LetterFromTheEditor = () => {
	const footerContext = useFooterContext();
	useEffect(() => {
		footerContext.setArticleType("");
		document.title = "Letter from the Editor";
	}, [footerContext]);

	return (
		<div className="letter-editor-bg">
			<div className="container">
				<div className="letter-editor">
					<h1 className="header">LETTER FROM THE EDITOR</h1>
					<div
						style={{
							fontSize: "1.5rem",
              textAlign: "justify"
						}}
					>
						<p>
							When I was a kid, my dad used to drive our family down the west
							coast of North America every chance he’d get. We’d start in my
							hometown of Vancouver, line up at the Peace Arch border crossing,
							and meander along the coastline of Oregon. A few times, we made it
							all the way to California. Now, years have passed since the last
							of these road trips, but I like to think that somewhere in time, I
							am still sitting in the backseat of that purple van and we are
							still hurtling down the highway. In my mental reel of these
							precious childhood memories, sometimes we’re headed to the closest
							city to seek out dinner. The sky is all radiant pinks and oranges,
							spreading through the clouds like bleeding ink. Sometimes, night
							has fallen and the rearview mirror is bright with the steady glare
							of headlights. My little sister is always asleep on my shoulder.
						</p>
						<p>
							The past is a strange thing. No one can truly return to it, and
							yet no one can leave it behind. It lingers in the present and it
							is inscribed into the future. So, maybe we can only understand
							where we’re going by mapping out where we’ve been. That doesn’t
							bode well for me though — often, when I try to recall a memory, it
							feels a lot like grasping at smoke. Here’s an example: I’ve lost
							track of why this magazine is called “HINDSIGHT.” But I tell
							myself that what matters is what we’ve made of it.
						</p>
						<p>
							In this little digital magazine, writers and artists confront
							histories both broad and personal. Rhea explores <a href="https://hindsight.thevarsity.ca/article/tales-from-the-closet"> 
							what it’s like to be queer in the isolation caused by the pandemic</a>, 
							and Alice paints a picture of <a href="https://hindsight.thevarsity.ca/article/longing-for-shanghai">
							how it feels when home unexpectedly slips away</a>. 
							Alexa <a href="https://hindsight.thevarsity.ca/article/a-day-with-tiktoks-magic-matt"> 
							unpacks the journey of Canada’s most followed TikTok magician</a>, and
							Riley delves into the experience of <a href="https://hindsight.thevarsity.ca/article/sex-in-the-locked-down-city">
							finding and losing love during a global crisis</a>.  In other pieces, 
							the past challenges us to shift our perspectives: 
							Safiya <a href="https://hindsight.thevarsity.ca/article/nostalgia-isnt-what-it-used-to-be"> 
							returns to the roots of nostalgia</a> to argue that it has evolved 
							into something sinister, and Sahir imagines what it’ll be like 
							to <a href="https://hindsight.thevarsity.ca/article/the-future-you-feared">
							reflect on the climate crisis in 100 years</a>.
						</p>
						<p>
							Ultimately, I believe storytelling is our best bet for drawing
							meaning out of the past. I hope the stories we’ve laid out here
							prompt you to consider your own with fresh wonder.
						</p>
            <br />
						<p>All my love,</p>
						<p>Jadine Ngan, Magazine Editor</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LetterFromTheEditor;
