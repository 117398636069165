import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../Styles/Article.css";
import Logo from "../../Assets/footer-logo.svg";
import { useFooterContext } from "../../Contexts/FooterContext";
import Flipbook from "../../Components/Flipbook";

type ArticleType = {
	title: string;
	hed: string;
	dek: string;
	featured_image?: string;
	featured_video?: string;
	body: string;
	articleType: string;
	byLine: string;
};

const GreatGrandfatherCollage = () => {
	const params = useParams();

	const footerContext = useFooterContext();

	const [article, setArticle] = useState<ArticleType>({
		title: "",
		hed: "",
		dek: "",
		featured_image: "",
		featured_video: "",
		body: "",
		articleType: "",
		byLine: "",
	});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		try {
			const data = require(`../../Articles/great-grandfather-collage.json`);

			setTimeout(() => {
				setArticle({
					title: data.title,
					hed: data.hed,
					dek: data.dek,
					featured_image: data.featured_image,
					featured_video: data.featured_video,
					body: "",
					articleType: data.type || "",
					byLine: data.byline,
				});
				document.title = data.title;
				console.log(article);
				footerContext.setArticleType(data.type || "");
				setLoading(false);
			}, 1000);
		} catch (e) {
			console.log(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	return (
		<>
			<div
				style={{
					position: "fixed",
					top: 0,
					width: "100%",
					height: "100%",
					left: 0,
					right: 0,
					bottom: 0,
					opacity: loading ? 1 : 0,
					backgroundColor: "rgba(252,252,252)",
					transition: "all 0.5s ease-out",
					zIndex: 100,
					visibility: loading ? "visible" : "hidden",
				}}
			>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						textAlign: "center",
						fontSize: "2em",
						fontWeight: "bold",
						width: "100%",
						height: "100%",
					}}
				>
					<img src={Logo} alt="loading" />
				</div>
			</div>
			<div>
				{article.featured_video && (
					<div
						style={{
							width: "100%",
							height: "100%",
							backgroundColor: "black",
						}}
					>
						<video
							muted={true}
							controls={false}
							autoPlay={true}
							loop={true}
							preload="auto"
							style={{
								marginTop: "-40px",
								width: "100%",
								height: "auto",
								maxHeight: "70vh",
								marginBottom: "-5px"
							}}
						>
							<source src={article.featured_video} type="video/mp4" />
						</video>
					</div>
				)}
				<div
					className="article-body"
					style={{
						paddingBottom: "40px",
					}}
				>
					<div className="header">
						<h1
							style={{
								paddingBottom: "20px",
								transition: "all 0.2s ease-in-out",
							}}
						>
							{article.hed}
						</h1>
						{article.dek && <h3>{article.dek}</h3>}
						{article.byLine && (
							<h5 style={{ paddingTop: 10 }}>By {article.byLine}</h5>
						)}
					</div>
					<p>
						I have privilege: I have the ability to explore hundreds of years of
						my family’s ancestry through archival documents.{" "}
					</p>

					<p>
						I’ve always been curious about my family history, and recently, I
						have come to know more about my maternal great-grandfather’s family.
						With the help of my grandfather, through personal research through
						Canadian archives, I have been able to learn about the lives of
						family members from as far back as the early 1800s.{" "}
					</p>

					<p>
						My curiosity stemmed mostly from my lack of knowledge about my
						family history, particularly the maternal side of my family. I knew
						my great-grandfather before he passed, but not very well. I didn’t
						know anything about his parents or my great-grandmother’s parents. I
						wanted to learn more about my family’s relationship to the country
						we have been living in for so long.{" "}
					</p>

					<p>
						After talking to my friends about this experience, however, I
						realized that much of this knowledge is available to me because I am
						a white Canadian whose family has been in Canada for hundreds of
						years. The histories of many other groups have been erased and
						ignored for centuries at the hands of colonizers.{" "}
					</p>

					<p>
						Many people have no government documentation, like birth or marriage
						records, that keeps track of their family history. Household census
						data and border crossing records are difficult to access, because,
						in most circumstances, they simply don’t exist. I recognize that my
						ability to learn about my family history is one of my many
						privileges, and I hope that future generations of people in Canada
						can also learn about their histories as easily.{" "}
					</p>

					<p>
						The conversations I had with my grandfather on this topic are
						something that I will forever hold close to my heart. My grandfather
						lived in another country while I was growing up and would only visit
						us occasionally. We would chat sometimes, but I felt like our
						relationship was mostly superficial.
					</p>

					<p>
						But in the last month, we learned about our family history together,
						through historical documentation. It was a moving experience to see
						my great-great-great-great-grandparents’ penmanship and the
						signatures on their marriage licence. In some moments, I simply
						listened to my grandfather recall his memories out loud.{" "}
					</p>

					<p>
						I am happy that our relationship has strengthened, and that I have
						learned more about his childhood and his father and grandparents. I
						hope to continue learning about my family in the years to come and
						to pass this knowledge down to future generations of our family.
					</p>
					<Flipbook
						images={[
							"https://hindsight-cdn.thevarsity.ca/hindsight/great-grandfather-collage-1.origin.jpg",
							"https://hindsight-cdn.thevarsity.ca/hindsight/great-grandfather-collage-2.origin.jpg",
							"https://hindsight-cdn.thevarsity.ca/hindsight/great-grandfather-collage-3.origin.jpg",
							"https://hindsight-cdn.thevarsity.ca/hindsight/great-grandfather-collage-4.origin.jpg",
							"https://hindsight-cdn.thevarsity.ca/hindsight/great-grandfather-collage-5.origin.jpg",
							"https://hindsight-cdn.thevarsity.ca/hindsight/great-grandfather-collage-6.origin.jpg",
						]}
					/>
					<p>
						<em>
							Visuals by Dalainey Gervais, cover visual by Caroline Bellamy.
						</em>
					</p>
				</div>
			</div>
			<style>
				{`
            html {
                overflow-y: ${loading ? "hidden" : "scroll"};
            }
        `}
			</style>
		</>
	);
};

export default GreatGrandfatherCollage;
