import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../Styles/Article.css";
import "../../Styles/MagicMatt.css";
import Logo from "../../Assets/footer-logo.svg";
import { useFooterContext } from "../../Contexts/FooterContext";

type ArticleType = {
	title: string;
	hed: string;
	dek: string;
	featured_image?: string;
	body: string;
	articleType: string;
	byLine: string;
};

const MagicMatt = () => {
	const params = useParams();

	const footerContext = useFooterContext();

	const [article, setArticle] = useState<ArticleType>({
		title: "",
		hed: "",
		dek: "",
		featured_image: "",
		body: "",
		articleType: "",
		byLine: "",
	});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		try {
			const data = require(`../../Articles/a-day-with-magic-matt.json`);

			setTimeout(() => {
				setArticle({
					title: data.title,
					hed: data.hed,
					dek: data.dek,
					featured_image: data.featured_image,
					body: "",
					articleType: data.type || "",
					byLine: data.byline,
				});
				document.title = data.title;
				footerContext.setArticleType(data.type || "");
				setLoading(false);
			}, 1000);
		} catch (e) {
			console.log(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	return (
		<>
			<div
				style={{
					position: "fixed",
					top: 0,
					width: "100%",
					height: "100%",
					left: 0,
					right: 0,
					bottom: 0,
					opacity: loading ? 1 : 0,
					backgroundColor: "rgba(252,252,252)",
					transition: "all 0.5s ease-out",
					zIndex: 100,
					visibility: loading ? "visible" : "hidden",
				}}
			>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						textAlign: "center",
						fontSize: "2em",
						fontWeight: "bold",
						width: "100%",
						height: "100%",
					}}
				>
					<img src={Logo} alt="loading" />
				</div>
			</div>
			<div>
				<div
					style={{
						background: article.featured_image
							? `url(${article.featured_image})`
							: "white",
						backgroundSize: article.featured_image ? "cover" : "contain",
						backgroundPosition: article.featured_image ? "center" : "",
						height: "100vh",
						width: "100%",
					}}
				>
					<h1
						style={{
							paddingTop: "12vh",
							paddingLeft: "10vw",
							transition: "all 0.2s ease-in-out",
							color: "white",
							fontSize: "3em",
						}}
						dangerouslySetInnerHTML={{ __html: article.hed }}
					></h1>
					{article.dek && <h3>{article.dek}</h3>}
					{article.byLine && (
						<h5
							style={{
								paddingTop: "10vh",
								paddingLeft: "10vw",
								color: "white",
								fontSize: "2rem",
							}}
						>
							By {article.byLine}
						</h5>
					)}
				</div>
				<div className="m-container">
					<div className="m-body">
						<p>
							Ten minutes after we originally planned to meet, Matthew Palandra
							sends me a text: “I am horrible with directions, I could never be
							a geographer.”
						</p>
						<p>
							The message, though followed immediately by a laughing emoji, is
							surprisingly introspective. A few moments ago, I — a visiting
							student at UTSG who was about to step foot in Sidney Smith Hall
							for the first time — found Palandra’s classroom before he did.
							It’s an auditorium-style room, which Palandra agrees smells like
							sweat. “That’s engineering for ya 😂,” he texts when I mention the
							smell.
						</p>
						<p>
							And there he comes, as lofty as advertised — “a tall guy with a
							black shirt” was the description he sent me beforehand. Until he
							reaches me, I’m only able to catch a glimpse of Palandra, since
							his torso is blocked from view by the scurrying heads of passing
							students. As Palandra approaches, I try to predict whether his
							personality will be as distinct as his stature.
						</p>
						<p>
							It must be. Since Palandra first started posting on his Tiktok
							account in 2020 — an account that the rising star has dedicated to
							executing magic tricks, performing skits, and making paper art —
							it has amassed nearly three million followers. When you consider
							that he has over 80,000 Instagram followers across his two
							accounts and more than 346,000 YouTube subscribers, it’s fair to
							assume that the magician is well-known to many of the people who
							pass by his lecture hall.
						</p>
						<p>
							These suspicions are confirmed almost immediately after I
							officially meet Palandra; as soon as we’re introduced, he turns
							around to laugh with another student approaching him. The student
							hugs me and swings open the lecture hall door so I can enter. “Are
							you an engineering student, too?”
						</p>
						<p>
							I tell him that I’m writing an article, and then pause. “Who did
							you think I was?”
						</p>
						<p>“I don’t know.” He shrugs. “Matthew has lots of friends.”</p>
					</div>
				</div>
				<video
					autoPlay={true}
					loop={true}
					muted={true}
					controls={false}
					className="gif-display"
					style={{
						marginBottom: 0,
					}}
				>
					<source src="https://hindsight-cdn.thevarsity.ca/hindsight/a-day-with-tiktoks-magic-matt-gif-1.journey.mp4" type="video/mp4" />
				</video>

				<div className="m-container">
					<div className="m-body">
						<p>
							<strong>11:00 am:</strong> “I was verified on YouTube yesterday.”
							The announcement, which means Palandra has amassed more than
							100,000 follows on the social media platform, is made to whoever
							happens to be within earshot in the magician’s first class of the
							day — a programming fundamentals lecture. Around us, students type
							furiously on their laptops, their heads bowed so that their
							professor — an elderly man with a booming voice — doesn’t call on
							them to answer his questions.
						</p>
						<p>
							A large group takes their seats in the row behind us. They turn
							back when they see Palandra, visibly startled. “Matthew, you’re
							here?” one asks, patting him on the shoulder. “What’s the occasion
							— do we have a midterm today?”
						</p>
						<p>
							<strong>12:00 pm:</strong> “I’m double booked with the press
							today,” Palandra calls over his shoulder, his black and white
							ASICS bouncing off the pavement beneath us as we run to the Bahen
							Centre for Information Technology. As promised, Palandra is
							horrible at following directions, and we’ve made countless wrong
							turns along the way.
						</p>
						<p>
							I assume we’re meeting one of Palandra’s friends before his
							afternoon class. But the only person standing beside the building
							is a woman with her back turned to us. Palandra jumps in front of
							her and smiles.
						</p>
						<p>
							“You’re from Humans of Skule, right?” he asks. The woman nods.
							It’s an Instagram photo-blog that shares the stories of U of T
							engineering students. Palandra tells me that he was also featured
							last year.
						</p>
						<p>
							The woman struts the sidewalks as if we’re in the middle of Yonge
							and Dundas instead of on a university campus. As she weaves
							between passersby, congested groups of friends, and bicycle riders
							gripping dog leashes, she powers on her phone’s recording software
							to capture Palandra’s brief story about performing a magic trick
							for the dean of engineering. She then directs Palandra to pose,
							tossing her phone to the ground so that she can hold her camera
							with both hands. I offer to hold the phone; she picks it up and
							gives it to me without turning away from her lens.
						</p>
						<p>
							The photoshoot lasts 10 minutes. As students frantically move
							around Palandra to reach their classes, he smiles with his arms
							crossed and his feet firmly planted, a silent statement that
							exudes self-confidence.
						</p>
						<p>
							<strong>12:30 pm:</strong> We’re lost once again. This time, it’s
							because we want lunch. Palandra, having recently learned that I’m
							pescetarian, is unsure of the suitability of the menu of the food
							trucks which regularly line St. George Street. “I usually get
							lunch from there,” he admits.
						</p>
						<p>
							After agreeing to eat sushi — a favourite meal for both of us —
							Palandra leads me to the Medical Sciences building, where he
							remembers his friend buying a “good-looking roll.” He calls said
							friend to confirm, and gets a response immediately.
						</p>
						<p>
							I order a spicy salmon poke bowl and Palandra buys a dynamite
							roll, despite having already packed a lunch. “Might as well,” he
							quips when he learns that I’m eating.
						</p>
						<p>
							We choose a bench outside of the building and begin to talk.
							Palandra describes how he became interested in learning magic
							after seeing Shin Lim, a celebrity magician, perform on{" "}
							<em>America’s Got Talent. </em>After posting a trick on his
							Snapchat story, old friends reached out to Palandra to compliment
							his talents — praise which ultimately led him to create an
							Instagram account dedicated to showcasing his tricks.
						</p>
						<p>
							“My girlfriend [at the time] said, ‘You’ve got to post on TikTok…
							It was so bad that I got no views. But then there’s a quarantine.
							I had nothing to do so I started posting more. It was a rough
							ride; three months with hate comments and low views.”
						</p>
						<p>
							Unsatisfactory reactions are disheartening when you consider that
							Palandra spends 30 minutes to an hour on each video he makes. The
							magician, however, shrugs off my sympathies and motions towards
							his veal sandwich: “Will you be offended if I eat this in front of
							you?”
						</p>
						<p>
							<strong>1:45 pm:</strong> Palandra’s afternoon lecture has been in
							session since 1:00 pm, but he decides that sitting in on its last
							15 minutes will suffice. “Most of my lectures are online; I can
							just watch them later,” he explains.
						</p>
						<p>
							This time, we’re learning about functions. From the back of the
							classroom, Palandra squints at the chalk-streaked blackboard,
							copying his professor’s hasty movements into the same notebook
							that he’d scribbled in earlier.
						</p>
						<p>
							“Do you organize your notes by class?” I ask. He shakes his head.
							“So what do you do before tests?”
						</p>
						<p>“Just skim through and take what I need.”</p>
						<p>
							<strong>2:00 pm:</strong> Palandra decides that we’re visiting the
							U of T Bookstore. He needs to return a t-shirt that he purchased
							for his mom. Appropriately, the shirt proclaims “University of
							Toronto Mom” across its front.
						</p>
						<p>
							We rummage through the shelves of the design, skimming their back
							tags for the right size. “She needs a large,” Palandra sighs as we
							dig. “She likes her clothes to be baggy. I bought a medium, and
							then I had to exchange it, but they were sold out. And then I went
							back after weeks, and they were still sold out.”
						</p>
						<p>
							The bookstore was indeed sold out again. Palandra is told a new
							shipment is to arrive in two weeks. He receives a refund via gift
							card, and we head to <em>The Varsity</em>’s office.
						</p>
						<p>
							<strong>2:30 pm: </strong>“It took me half an hour to find this
							place the first time.” Palandra’s referring to a week earlier,
							when he barged into the office to pitch us a biographical feature
							on him. Later that night, he’d drafted guidelines on what the
							piece was to focus on:
						</p>
						<ol>
							<li>I am the most followed magician on TikTok in Canada.</li>
							<li>
								My journey definitely sheds a positive light on COVID-19, as
								without quarantine I never would’ve started posting online and
								been in a situation where I was able to pursue the opportunity
								of social media.{" "}
							</li>
							<li>
								My journey also encapsulates themes of mental health struggles
								as when I started off, and even now I still receive hate
								comments.{" "}
							</li>
						</ol>
						<p>
							I tug open the office door, and Palandra’s enthusiastic voice
							floats off its walls: “It’s one thing to find the office in two
							seconds, but to take half an hour to look for it — that’s
							dedication.”
						</p>
						<p>
							<strong>2:45 pm:</strong> We’ve finally sat down for an interview in{" "}
							<em>The Varsity</em>’s design office. The lights are off, and a
							cool breeze drafts in from an open window behind me. Strangers’
							voices carry up from the window, busy with energetic chatter.
						</p>
					</div>
				</div>
				<video
					autoPlay={true}
					loop={true}
					muted={true}
					controls={false}
					className="gif-display"
					style={{
						marginBottom: 0,
					}}
				>
					<source src="https://hindsight-cdn.thevarsity.ca/hindsight/a-day-with-tiktoks-magic-matt-gif-4.journey.mp4" type="video/mp4" />
				</video>
				<div className="m-container">
					<div className="m-body">
						<p>
							<strong>Matthew Palandra</strong>: My grandfather passed away,
							which was really unfortunate. When I was younger, I used to do
							these horrible magic tricks for my great-grandmother. She sent me
							a magic video, and I thought it would be funny if I did some
							tricks because they were never good. I called one of my cousin's
							friends who also did magic. I said, “Do you want to go perform a
							magic show?” He graciously said, “Yeah, sure.” So we went to my
							great-grandmother's old age home and we did the show. And then
							COVID-19 came and I couldn't perform for anyone.
						</p>
						<p>
							<strong>
								<em>The Varsity</em>
				
							: So that was when you got TikTok?</strong>
						</p>
						<p>
							<strong>MP</strong>: Yeah. I kept posting. I
							started to find out what people engage with. I found that just
							posting plain tricks on a screen doesn’t work the best because
							people think they’re edited or the lighting is bad. It’s just
							those little things that can really affect it. It was sad, right?
							I loved performing before quarantine. And then I’m trying to show
							it to people online, just getting no traction.
						</p>
						<p>
							<strong>
								<em>TV</em>
							
							: If you continued to get no traction, would you still be
							uploading videos?</strong>
						</p>
						<p>
							<strong>MP</strong>: I always knew that I wanted to be successful
							at it. I was always trying to change and get inspiration from
							others. One of the big things that affected me was that the
							biggest magician on TikTok followed me back and he was really
							encouraging. But another thing that I learned is trying to copy
							what other people are doing is usually not going to end well.
							You’re going to be seen as a knockoff of someone else. Another
							thing I learned is that no one cares about you; you have to try to
							relate to the viewer. And the only way you can do that is by being
							original.
						</p>
						<p>
							<strong>
								<em>TV</em>
							
							: Is that why you have multiple social media
							accounts? You were telling me you had three Instagram accounts and
							a storytelling account on TikTok.</strong>
						</p>
						<p>
							<strong>MP</strong>: It helps with brand
							opportunities, too. I know people who promote makeup, right?
							Obviously, a makeup brand’s going to them before someone who
							promotes makeup and sports. So I broke it up to try to define my
							audience more clearly.
						</p>
						<p>
							<strong>
								<em>TV</em>
							
							: You remind me of Kris Jenner with your social
							media strategy.</strong>
						</p>
						<p>
							<strong>MP</strong>: It's so calculated. A lot of people don't see
							that. If someone were to look at my account, they’d think, ‘He has
							millions of followers, his views are good. He’s probably such a
							happy guy.’ I was talking to my friend; he does challenges like
							eating the hottest chip in the world. He said that he has had to
							go to the hospital twice from throwing up all night. And he
							thought he was going to die just for a four-minute video. He did
							one walking for 24 hours and got stress fractures in both of his
							feet, and they were so blistered. You’d never see this behind the
							scenes.
						</p>
						<p>
							<strong>
								<em>TV</em>
							
							: Usually, when people say, ‘Don't compare your
							social media,’ it’s about Instagram and someone’s selfie looks
							better than yours. It’s not in the context of content creators.</strong>
						</p>
						<p>
							<strong>MP</strong>: There’s one video I posted. It got 8,000
							views. I posted the same video again and it was 40,000. I posted
							it a third time and I got 3.2 million views. A lot of it is just
							luck.
						</p>
						<p>
							<strong>
								<em>TV</em>
							
							: What you're doing now is interesting to me
							because you’re managing engineering, which is so different from
							being a magician.</strong>
						</p>
						<p>
							<strong>MP</strong>: I remember when everything was going so well.
							Social media is just failure after failure and I was happier
							before. Obviously it’s awesome to have all these opportunities and
							do what I love but I wonder if I’d be better off living on a farm,
							just milking a cow.
						</p>
						<p>
							<strong>
								<em>TV</em>
							
							: The good thing is that, if you get to the point
							where it really bothers you, you could lock yourself out of all
							your social media.</strong>
						</p>
						<p>
							<strong>MP</strong>: Yeah. Just the other day, there was a
							copyrighted song in one of my videos and I was trying to change
							it. I muted the video for a minute and I got 20 comments asking,
							‘Where's the sound?’ Why are they constantly watching everything
							I’m doing? But if I take a night off, everyone’s going to notice.
						</p>
						<p>
							<strong>
								<em>TV</em>: {" "}
							
							Do you want to say anything else before we wrap up? Holy shit.
							It’s 3:16 and we started at 2:30.</strong>
						</p>
						<p>
							<strong>MP</strong>: Wow, we got some good footage for you. For
							us, I suppose. I just want to see if there’s anything in the Gmail
							—
						</p>
						<p>
							<strong>
								<em>TV</em>: {" "}
							
							You’re going through your pitch thinking, ‘Did they cover this?’</strong>
						</p>
						<p>
							<strong>MP</strong>: Actually, you know how to write. That’s like
							you telling me how to do magic. I hate that. I’ll let you write
							your article.
						</p>
						<p>
							<em>This interview has been edited for length and clarity. </em>
						</p>
						<p>
							<em>
								Visuals by Abigail Dollries, background illustrations by Jadine
								Ngan.{" "}
							</em>
						</p>
					</div>
				</div>
			</div>
			<style>
				{`
            html {
                overflow-y: ${loading ? "hidden" : "scroll"};
            }
        `}
			</style>
		</>
	);
};

export default MagicMatt;
