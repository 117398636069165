import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../Styles/Article.css";
import "../../Styles/FutureYouFeared.css";
import Logo from "../../Assets/footer-logo.svg";
import { useFooterContext } from "../../Contexts/FooterContext";
import { ReactComponent as PauseIcon } from "../../Assets/pause.svg";
import { ReactComponent as PlayIcon } from "../../Assets/play.svg";

type ArticleType = {
	title: string;
	hed: string;
	dek: string;
	featured_image?: string;
	body: string;
	articleType: string;
	byLine: string;
};

type AudioPlayerProps = {
	audioSrc: string;
};

const AudioPlayer = ({ audioSrc }: AudioPlayerProps): JSX.Element => {
	const [audio, setAudio] = useState<HTMLAudioElement>(new Audio());

	useEffect(() => {
		setAudio(new Audio(audioSrc));
	}, [audioSrc]);

	const [duration, setDuration] = useState(0);
	const [isPaused, setIsPaused] = useState(true);
	const [currentTime, setCurrentTime] = useState(0);

	const getTimeString = (time: number): string => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);

		return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
	};

	audio.addEventListener("durationchange", () => {
		setDuration(Math.floor(audio.duration));
	});

	audio.addEventListener("ended", () => {
		setIsPaused(true);
	});

	audio.addEventListener("timeupdate", () => {
		setCurrentTime(Math.floor(audio.currentTime));
	});

	return (
		<div className="audio-player">
			{/* Show play/pause button */}
			<div>
				<button
					className="audio-player__play-pause"
					onClick={() => {
						if (isPaused) {
							audio.play();
							setIsPaused(false);
						} else {
							audio.pause();
							setIsPaused(true);
						}
					}}
				>
					{isPaused ? (
						<PlayIcon fill="white" width={16} />
					) : (
						<PauseIcon fill="white" width={16} />
					)}
				</button>
			</div>
			<div className="audio-scrubber">
				<input
					type="range"
					min="0"
					max={duration}
					step="0.01"
					style={{
						width: "100%",
					}}
					value={currentTime}
					onChange={(e) => {
						audio.currentTime = Number.parseInt(e.target.value);
					}}
				/>
			</div>
			<div className="audio-player__time-display">
				<span>{getTimeString(currentTime)}</span>/
				<span>{getTimeString(duration)}</span>
			</div>
		</div>
	);
};

const FutureYouFeared = () => {
	const params = useParams();

	const footerContext = useFooterContext();

	const [article, setArticle] = useState<ArticleType>({
		title: "",
		hed: "",
		dek: "",
		featured_image: "",
		body: "",
		articleType: "",
		byLine: "",
	});

	const [showScript, setShowScript] = useState(false);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		try {
			const data = require(`../../Articles/the-future-you-feared.json`);

			setTimeout(() => {
				setArticle({
					title: data.title,
					hed: data.hed,
					dek: data.dek,
					featured_image: data.featured_image,
					body: "",
					articleType: data.type || "",
					byLine: data.byline,
				});
				document.title = data.title;
				footerContext.setArticleType(data.type || "");
				setLoading(false);
			}, 1000);
		} catch (e) {
			console.log(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	return (
		<>
			<div
				style={{
					position: "fixed",
					top: 0,
					width: "100%",
					height: "100%",
					left: 0,
					right: 0,
					bottom: 0,
					opacity: loading ? 1 : 0,
					backgroundColor: "rgba(252,252,252)",
					transition: "all 0.5s ease-out",
					zIndex: 100,
					visibility: loading ? "visible" : "hidden",
				}}
			>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						textAlign: "center",
						fontSize: "2em",
						fontWeight: "bold",
						width: "100%",
						height: "100%",
					}}
				>
					<img src={Logo} alt="loading" />
				</div>
			</div>
			<div>
				<div
					style={{
						background: article.featured_image
							? `url(${article.featured_image})`
							: "white",
						backgroundSize: article.featured_image ? "cover" : "contain",
						backgroundPosition: article.featured_image ? "center" : "",
						height: "70vh",
						width: "100%",
						marginBottom: "20px",
					}}
				/>

				<div
					className="article-body"
					style={{
						paddingBottom: "40px",
					}}
				>
					<div className="header" style={{ paddingBottom: "100px" }}>
						<h1
							style={{
								paddingBottom: "20px",
								transition: "all 0.2s ease-in-out",
							}}
						>
							{article.hed}
						</h1>
						{article.dek && <h3>{article.dek}</h3>}
						{article.byLine && (
							<h5 style={{ paddingTop: 10 }}>By {article.byLine}</h5>
						)}
					</div>
					<div className="ffyf-container" style={{ marginBottom: "5vh", marginTop: "5vh" }}>
						<p>
							Description: This is a podcast episode set in the twenty-second
							century. It’s a world in which the nature that we know and love
							today has been reduced drastically to something unrecognizable.
							Concerns about fresh air, water, and wildlife have skyrocketed,
							and unpredictable weather phenomena are the norm. There’s no going
							back now, so we survive the best we can, and ask: how did we end
							up here?
						</p>
					</div>
					<AudioPlayer
						audioSrc={
							"https://hindsight-cdn.thevarsity.ca/hindsight/future-you-feared.shift.mp3"
						}
					/>
					<div className="ffyf-toggle-script-container">
						<button
							className="ffyf-toggle-script"
							onClick={(e) => {
								e.preventDefault();
								setShowScript(!showScript);
							}}
						>
							{showScript ? "Hide script" : "Show script"}
						</button>
					</div>
					<div
						className={`ffyf-container`}
						style={{
							marginTop: "5vh",
							display: showScript ? "block" : "none",
						}}
					>
						<div className="poem">
							<p>
								A Bird, came down the Walk - <br />
								He did not know I saw -<br />
								He bit an Angle Worm in halves
								<br />
								And ate the fellow, raw,
							</p>
							<p>
								And then, he drank a Dew
								<br />
								From a convenient Grass -<br />
								And then hopped sidewise to the Wall
								<br />
								To let a Beetle pass -
							</p>
							<p>
								He glanced with rapid eyes,
								<br />
								That hurried all abroad -<br />
								They looked like frightened Beads, I thought,
								<br />
								He stirred his Velvet Head. -
							</p>
							<p>
								Like one in danger, Cautious,
								<br />I offered him a Crumb,
								<br />
								And he unrolled his feathers, <br />
								And rowed him softer Home -
							</p>
							<p>
								Than Oars divide the Ocean,
								<br />
								Too silver for a seam,
								<br />
								Or Butterflies, off Banks of Noon,
								<br />
								Leap, plashless as they swim.
							</p>
						</div>
						<p style={{
							textAlign: "right",
						}}>— “A Bird, came down the Walk -” by Emily Dickinson</p>
						<br />
						<p>
							It’s a poem I’ve always found curious. The poem’s natural
							connection with the world seems so surreal to me today; so out of
							place and rare. How did we ever get here?
						</p>
						<p>[Audio track of birds and wind rustling the leaves]</p>
						<p></p>
						<p>
							What you hear now is the pure, unfiltered noise of the wild. We’re
							in one of the few places left in the world where nature is so
							well-preserved. You can hear the birds singing, and wind rushing
							through the leaves. You can feel the presence of ancient trees and
							almost taste the freshness of the air.
						</p>
						<p>
							It’s beautiful. You can tell just by standing here how poems about
							nature were so easily written and inspired. I could honestly get
							lost here for a couple of days and wouldn’t mind in the slightest.
							But, alas, this is a pricey exhibit and we do need to head out
							now.
						</p>
						<p>[Bird noises and all cut out]</p>
						<p>
							It’s been less than a minute since I’ve left the exhibit and I
							already want to go back. And so does everyone who comes to visit.
							You can just smell the change as soon as you leave. You feel the
							way the sounds of the birds are replaced by the near-silent
							whirring of robots and machinery all around, and how the calming
							rustle of leaves is replaced by the strained exhale of recycled
							air. There will be no poems written here, except maybe about how
							much we wish we could go back to the world of the twenty-first
							century.
						</p>
						<p>
							We used to have massive forests that were safe to visit and camp
							out in. Pictures from the early twentieth century show that just
							down the street from here, there used to be a small park with its
							own lake and grove of trees. A few kilometres away, there was a
							small hiking trail. It used to be everywhere, this beauty and
							peace. Now, you need to pay a hundred dollars to see even a little
							display of it. How did we end up here?
						</p>
						<p>
							Let’s first head back to the twentieth and twenty-first centuries,
							the times during and immediately after World War I, World War II,
							and the First Cold War.
						</p>
						<p>
							During the late 1900s, companies and governments — especially the
							ones finding and selling fossil fuels — were slowly recognizing
							the sheer environmental impact that the industrial revolution and
							the world wars had. But almost none of this information was ever
							released to the public. Companies producing fossil fuels had hired
							teams of researchers to test the impacts of their fuels, and upon
							discovering that they were doing unthinkable levels of damage,
							they chose not to act.
						</p>
						<p>
							Actually, that’s a lie. Rather than solving the issue, they
							actively inhibited any attempts to fix it, altering studies and
							publishing false information, all in the name of profit. These
							practices caused immense damage for literal centuries, and might
							have been one of the biggest turning points that led us to where
							we are today.
						</p>
						<p>
							If those company owners had released the true, honest reports
							earlier, perhaps their great grandchildren wouldn’t despise them
							and curse their generation as much as we do today. If they had
							taken action sooner, maybe we wouldn’t be in such a broken world.
							Maybe we’d still have clean, fresh air, and non-destructive
							weather.
						</p>
						<p>
							But I digress. We’re not here to discuss the what-ifs — we already
							know what happened. We’re here to ask how we got here.
						</p>
						<p>
							Not all of recent history was bad, though. There were some moments
							of hope. In the early 2000s, there was a massive issue with the
							ozone layer. The ozone layer is the part of the Earth’s atmosphere
							responsible for protecting us from the sun’s ultraviolet
							radiation, which is immensely harmful to living things.
						</p>
						<p>
							From 1979 through 2011, governments and scientists came together
							to discuss the impacts this damage to the ozone layer could have
							and how to solve them. And they did solve the issue — for a while,
							at least. The governments and people of the world worked together
							and managed to reduce the destruction of the ozone to the point
							where the ozone layer was no longer being destroyed and was able
							to protect the Earth once again.
						</p>
						<p>
							But in the next couple of decades, this cooperation all fell
							apart. Governments broke promises and agreements, and failed to
							make any noticeable progress toward protecting the environment,
							and instead let companies and the rich elite destroy whatever they
							could.
						</p>
						<p>
							The Paris Agreement, also known as the Paris Climate Accords, is
							just one example of this failure. Its main point was to
							“strengthen the global response to the threat of climate change by
							keeping a global temperature rise to well below 2 degrees Celsius
							above pre-industrial levels and to limit the temperature increase
							even further to 1.5 degrees Celsius.”
						</p>
						<p>
							196 countries in the United Nations adopted this agreement, and
							they all set relatively similar targets for reducing carbon
							emissions by 2030. The Gambia was the only country to reach that
							goal by 2030, and many others have only reached it in the past
							couple of decades. And the Paris agreement didn’t even set an
							ambitious target. In fact, many environmental scientists
							criticized it for being too weak to combat climate change. It’s a
							sad fact that we won’t even know if that difference would have
							been enough, because clearly, the governments of the world did not
							care enough to even try.
						</p>
						<p>
							Now, while the corporations of the twenty-first century cared so
							little about the future, the same wasn’t true for most of the
							public. Trawling through the internet archives of that time, you
							can see that most people were fighting against these companies and
							trying to save the environment.
						</p>
						<p>
							There are records of countless demonstrations demanding that
							governments and corporations prioritize the environment, which
							gathered literal millions of protestors globally each year. And
							all these protests had the same calls to action: the environment
							is our future, and we will not stand for its destruction. Fossil
							fuels need to go; we want sustainable energy.
						</p>
						<p>
							A sizable bit of the population was influenced by a lot of
							propaganda that tried to deny climate change though, particularly
							in the Western world. In the United States, in particular,
							corporate propaganda ran wild, and it only grew with the
							popularization of social media.
						</p>
						<p>
							Just look at a popular app of the time called Twitter — where, for
							some reason, you had to express an entire point in 280 characters.
							You can see that there were a lot of people — including a US
							president — claiming that climate change didn’t even exist! It’s
							honestly fascinating how convinced they were about it — kind of
							revolting, considering the current state of our world today.
						</p>
						<p>
							But it still seems like most people wanted change. Week after
							week, people held protests all over the world. In some places,
							riots even broke out. Clearly, they didn’t do enough, though.
							Temperatures still kept rising year by year, while the Arctic and
							Antarctic regions shrunk faster than most climate models
							suggested, causing sea levels to rise and drowning entire cities.
							No matter how much they protested and shouted for change, it
							wasn’t enough. We still ended up where we are now, in the very
							future they were fighting against.
						</p>
						<p>
							For our ancestors to have avoided the future they feared, they
							would have needed to completely change the societies they lived
							in. They would have needed to overhaul the for-profit mindset that
							dominated the twentieth and twenty-first centuries and overthrow
							the greedy bastards who made this destruction of our planet
							possible. It would have required a revolution.
						</p>
						<p>
							Who knows why the people of that time didn’t revolt? They
							certainly would have had enough people on their side. And people
							had revolted earlier in history, so there’s no reason it shouldn’t
							have been possible then. If they were truly more scared of the
							risk to the future than to lose the comfort of their homes and
							jobs, they would have fought back harder.
						</p>
						<p>
							Instead, they allowed themselves to be placated time and time
							again by the false promises and lies that corporations fed to
							them. They allowed themselves to think they were making a
							difference, while, in fact, they were doing nothing at all.
						</p>
						<p>It’s tragic, really.</p>
						<p>
							While it is true that we shouldn’t blame the general public and
							should instead blame the ones in charge of the systems, it’s hard
							not to get angry at previous generations for not doing enough. And
							it’s harder still to separate ‘the good ones’ from ‘the bad ones,’
							because we just see the impact made by the generation as a whole.
						</p>
						<p>
							I suppose we’re all guilty of being placated by these kinds of
							lies, though, because we want to think that the world is okay.
							Even in today’s messed up world, with a weather warning every day
							and barely any semblance of that beautiful nature we hear so much
							about, we still feel like things are good sometimes.
						</p>
						<p>
							It’s a lot easier to believe that things are better than they are,
							instead of thinking about how ruined this world is now. It’s
							easier to write a poem than to save the world.
						</p>
						<div
							className="poem"
							style={{
								marginTop: "5vh",
							}}
						>
							<p>
								There will come soft rains and the smell of the ground,
								<br />
								And swallows circling with their shimmering sound;
							</p>
							<p>
								And frogs in the pools singing at night,
								<br />
								And wild plum trees in tremulous white,
							</p>
							<p>
								Robins will wear their feathery fire
								<br />
								Whistling their whims on a low fence-wire;
							</p>
							<p>
								And not one will know of the war, not one
								<br />
								Will care at last when it is done.
							</p>
							<p>
								Not one would mind, neither bird nor tree
								<br />
								If mankind perished utterly;
							</p>
							<p>
								And Spring herself, when she woke at dawn,
								<br />
								Would scarcely know that we were gone.
							</p>
						</div>
						<p>— “There Will Come Soft Rains” by Sara Teasdale</p>
						<p><em>Visuals by Kalliopé Anvar McCall.</em></p>
					</div>
				</div>
			</div>
			<style>
				{`
            html {
                overflow-y: ${loading ? "hidden" : "scroll"};
            }
        `}
			</style>
		</>
	);
};

export default FutureYouFeared;
