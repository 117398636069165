import React, { useEffect } from "react";
import { useFooterContext } from "../Contexts/FooterContext";
import "../Styles/Credits.css";

type CreditLine = {
	name: string;
	role: string;
};

const Credits = () => {
	const footerContext = useFooterContext();
	useEffect(() => {
		footerContext.setArticleType("");
		document.title = "Credits";
	}, [footerContext]);

	const creditsList: CreditLine[] = [
		{ role: "Magazine Editor", name: "Jadine Ngan" },
		{ role: "Creative Director", name: "Aditi Putcha" },
		{ role: "Editor-in-Chief", name: "Hannah Carty" },
		{ role: "Managing Online Editor", name: "Stephanie Bai" },
		{ role: "Managing Editor", name: "Tahmeed Shafiq" },
		{ role: "Senior Copy Editor", name: "Sarah Kronenfeld" },
		{ role: "Deputy Senior Copy Editor", name: "Nawa Tahir" },
		{ role: "Illustration Editor", name: "Andrea Zhao" },
		{ role: "Photo Editor", name: "Caroline Bellamy" },
		{ role: "Video Editor", name: "Abigail Dollries" },
		{ role: "Magazine Assistants", name: "Caleb Chan and Sahir Dhalla" },
		{ role: "Front-End Web Developer", name: "Aaron Hong" },
		{ role: "Back-End Web Developer", name: "Andrew Hong" },
		{ role: "Magazine Web Developer", name: "Zack Radisic" },
		{ role: "Associate Senior Copy Editor", name: "Talha Anwar Chaudhry" },
		{
			role: "Writers",
			name: "Marta Anielska, Jennifer Ayow, Alice Boyle, Mohamed Dasu, Madhurie Dhanrajh, Angad Deol, Sahir Dhalla, Alexa DiFrancesco, Dalainey Gervais, Piper Hays, Angel Hsieh, Rhea Jerath, Sky Kapoor, Isabella Liu, John Mace, Seth Martosh, Riley Myers, Safiya Patel, Jessie Schwalb",
		},
		{
			role: "Photographers",
			name: "Caroline Bellamy, Abigail Dollries, Sky Kapoor, Jadine Ngan",
		},
		{
			role: "Illustrators",
			name: "Jennifer Ayow, Alice Boyle, Angel Hsieh, Liya Huang, Georgia Kelly, Jessica Lam, Gladys Lou, Kalliope Anvar McCall, Rebeca Moya, Jadine Ngan, Cheryl Nong, Andrea Zhao",
		},
		{
			role: "Copy Editors",
			name: "Rachel Banh, Chiara Barsanti, Caleb Chan, Elise Cressatti, Julia Da Silva, Alyanna Denise Chua, Sahir Dhalla, Robert Guglielmin, Angel Hsieh, Liya Izmukhanova, Cedric Jiang, Anastasia Kasirye, Selena Ling, Mona Liu, Amanda Pompilii, Sanjaya Sritharan, Kiri Stockwood, Ajeetha Vithiyananthan, Grace Xu, Cherry Zhang",
		},
		{
			role: "Additional editing by",
			name: "Alyanna Denise Chua, Angad Deol, Maya Morriswala, Nawa Tahir, Hannah Carty",
		},
		{
			role: "Website design",
			name: "Jadine Ngan, Aditi Putcha, Makena Mwenda, Andrea Zhao, Aaron Hong, Andrew Hong",
		},
		{
			role: "Cover animation and homepage roadmap illustration",
			name: "Andrea Zhao",
		},
		{
			role: "Homepage and navigation bar icons",
			name: "Makena Mwenda, Aditi Putcha, Jadine Ngan",
		},
		{ role: "Website built by", name: "Aaron Hong, Andrew Hong" },
	];

	return (
		<div className="credits-bg">
			<div className="container">
				<div className="credits">
					<h1 className="header">CREDITS</h1>
					{creditsList.map((credit, index) => (
						<div className="credit-line" key={index}>
							<div className="role-line">{credit.role}</div>
							<div className="name-line">{credit.name}</div>
						</div>
					))}
          <hr className="credit-separator" />
          <div className="thanks-line">
            <strong>MANY THANKS, IN NO ORDER, TO:</strong> Caleb and Sahir, for lending your time and care to this project. Ingrid, for sharing your snacks and listening to all of my office stories. Aditi, for being a design genius, and for reminding me that I was doing my best under difficult circumstances. Nawa, for keeping me company during late nights in the office and early mornings at Slanted Door, and, through it all, having unshakeable faith in my ability to pull this off. Artie, for bringing me pineapple buns and finding the positive spin to every one of my qualms. Steph, for blazing the digital mag trail and giving the best hugs. Angad, for selflessly donating the pen and binder that held this project together. Alyanna, for helping run the pitch meeting and looking after the Features section during mag season. Andrea, our resident procreation expert, for drawing the pictures and bringing the chaos. Makena, for creating magical visuals, having brilliant design ideas, and doing it all in the coolest outfits. Charmain and Rian, for helping this Illustrator newbie troubleshoot. Caroline and Abby, for coordinating the multimedia elements that make this project sparkle. Tahmeed and Hannah, for checking in often and offering your unwavering support. Marta, Khatchig, Maya, and Mehrshad, for vibe checks and kind words. Aaron and Andrew, for translating our creative vision into reality — this magazine would not exist without your patience and incredible work. And of course, all the volunteers, who brought this magazine to life. A project like this truly takes a village, and I’m glad you all were part of mine. 
          </div>
				</div>
			</div>
		</div>
	);
};

export default Credits;
