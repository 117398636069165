import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../Styles/Article.css";
import "../../Styles/PostcardsFromChildhood.css";
import Logo from "../../Assets/footer-logo.svg";
import { useFooterContext } from "../../Contexts/FooterContext";

type ArticleType = {
	title: string;
	hed: string;
	dek: string;
	featured_image?: string;
	body: string;
	articleType: string;
	byLine: string;
};

const PostcardsFromChildhood = () => {
	const params = useParams();

	const footerContext = useFooterContext();

	const [article, setArticle] = useState<ArticleType>({
		title: "",
		hed: "",
		dek: "",
		featured_image: "",
		body: "",
		articleType: "",
		byLine: "",
	});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		try {
			const data = require(`../../Articles/postcards-from-childhood.json`);

			setTimeout(() => {
				setArticle({
					title: data.title,
					hed: data.hed,
					dek: data.dek,
					featured_image: data.featured_image,
					body: "",
					articleType: data.type || "",
					byLine: data.byline,
				});
				document.title = data.title;
				footerContext.setArticleType(data.type || "");
				setLoading(false);
			}, 1000);
		} catch (e) {
			console.log(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	return (
		<>
			<div
				style={{
					position: "fixed",
					top: 0,
					width: "100%",
					height: "100%",
					left: 0,
					right: 0,
					bottom: 0,
					opacity: loading ? 1 : 0,
					backgroundColor: "rgba(252,252,252)",
					transition: "all 0.5s ease-out",
					zIndex: 100,
					visibility: loading ? "visible" : "hidden",
				}}
			>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						textAlign: "center",
						fontSize: "2em",
						fontWeight: "bold",
						width: "100%",
						height: "100%",
					}}
				>
					<img src={Logo} alt="loading" />
				</div>
			</div>
			<div>
				<div
					style={{
						background: article.featured_image
							? `url(${article.featured_image})`
							: "white",
						backgroundSize: article.featured_image ? "cover" : "contain",
						backgroundPosition: article.featured_image ? "center" : "",
						height: "70vh",
						width: "100%",
						marginBottom: "20px",
					}}
				/>

				<div
					className="article-body"
					style={{
						paddingBottom: "40px",
					}}
				>
					<div className="header" style={{
						paddingBottom: "40px",
					}}>
						<h1
							style={{
								paddingBottom: "20px",
								transition: "all 0.2s ease-in-out",
							}}
						>
							{article.hed}
						</h1>
						{article.dek && <h3>{article.dek}</h3>}
						{article.byLine && (
							<h5 style={{ paddingTop: 10 }}>By {article.byLine}</h5>
						)}
					</div>
					<div className="intro-card">
						<div className="intro-card-body">
							<p>Dear reader,</p>
							<p>
								Transitioning into adulthood sometimes feels like being on a
								train to some unknown destination while the land of childhood
								gets smaller in the distance, forever greener in the rearview
								mirror. There’s a sinking feeling that comes with the
								realization that the train is already in motion and that I can
								no longer retrieve what I’ve left behind. It feels a lot like
								homesickness.
							</p>
							<p>
								While cleaning up my room over the summer, I couldn’t help but
								get excited every time I came across another item from my
								childhood. I savoured the rush of nostalgia — it felt like
								getting postcards from my younger self.{" "}
							</p>
							<p>
								My old stuffed toys greeted me with mottled, dust-covered fur —
								evidence that time had passed — but they are still full of
								memories and stories to cherish. Maybe we can’t turn back the
								clock and return to childhood once we’ve left it, but we can
								always cherish postcards from our younger selves.
							</p>
							<p>
								From, <br />
								Jennifer
							</p>
						</div>
					</div>
					<img
						className="blurb-img"
						src="https://hindsight-cdn.thevarsity.ca/hindsight/postcards-from-childhood-blurb-1.origin.png"
						alt="Blurb 1"
					/>
					<div className="blurb-body blurb-1">
						<p>
							I’m not exactly sure when it happened, but at some point, I
							stopped keeping stuffed toys on my bed and moved them all into
							their long-term home — my closet. Seeing their dusty fur again
							brought me back to the days when I would put on plays starring my
							stuffed animals. I carried them everywhere and pretended they were
							my pets after I’d repeatedly failed to convince my parents that I
							would be a responsible pet owner. Considering that I would forget
							to water my plants just a few months after I got them, my parents
							probably made the right decision.
						</p>
					</div>

					<img
						className="blurb-img"
						src="https://hindsight-cdn.thevarsity.ca/hindsight/postcards-from-childhood-blurb-2.origin.png"
						alt="Blurb 2"
					/>

					<div className="blurb-body blurb-2">
						<p>
							Going to the beach with my parents and brother was always a
							memorable outing. I would take off my sandals and wade into the
							cold water to hunt for unique-looking rocks. I remember finding a
							black rock with bubble-like pores in it at Cherry Beach, and later
							searching it up online to find out that it was a volcanic rock! It
							is now housed in an old toffee tin on my desk along with a
							fossilized shell. It’s safe to say they have both seen more
							interesting scenery.
						</p>
					</div>

					<img
						className="blurb-img"
						src="https://hindsight-cdn.thevarsity.ca/hindsight/postcards-from-childhood-blurb-3.origin.png"
						alt="Blurb 3"
					/>
					<div className="blurb-body blurb-3">
						<p>
							My favourite pair of pants in middle school was a pair of royal
							blue skinny jeans (skinny jeans, remember those?) with fake
							glittering gemstones on the back pockets. In the moment that my
							aunt gifted them to me, I knew it was love at first sight.
							Needless to say, they were definitely a statement and I wonder how
							I ever garnered the courage to wear them outside. Who knows,
							though — maybe this is a sign to go bold again?
						</p>
					</div>
					<p className="visual-credit">Visuals by Jennifer Ayow</p>
				</div>
			</div>
			<style>
				{`
            html {
                overflow-y: ${loading ? "hidden" : "scroll"};
            }
        `}
			</style>
		</>
	);
};

export default PostcardsFromChildhood;
