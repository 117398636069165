import React, { useState } from "react";
import { ReactComponent as LeftArrow } from "../Assets/left-arrow.svg";
import { ReactComponent as RightArrow } from "../Assets/right-arrow.svg";

type FlipbookProps = {
	images: string[];
    arrowColour?: string;
};

const Flipbook = ({ images, arrowColour="#A76C7E" }: FlipbookProps) => {
	// Show first image
	const [currentImage, setCurrentImageRaw] = useState(0);
	const [imageTransitioning, setImageTransitioning] = useState(false);

	const setCurrentImage = (index: number) => {
		if (imageTransitioning) return;
		setImageTransitioning(true);
		setTimeout(() => {
			let newIndex = index;
			if (index < 0) {
				newIndex = images.length - 1;
			} else if (index >= images.length) {
				newIndex = 0;
			}
			setCurrentImageRaw(newIndex);
			setTimeout(() => setImageTransitioning(false), 250);
		}, 250);
	};

	return (
		<div className="flipbook-container">
			<img
				src={images[currentImage]}
				alt={`${currentImage + 1} of ${images.length}`}
				className="fb-page"
				style={{
					opacity: imageTransitioning ? 0 : 1,
				}}
			/>

			<div className="fb-left">
				<button
					className="flipbook-button"
					onClick={() => {
						setCurrentImage(currentImage - 1);
					}}
				>
					<LeftArrow
						fill={imageTransitioning ? "gray" : arrowColour}
						height={64}
					/>
				</button>
			</div>
			<div className="fb-right">
				<button
					className="flipbook-button"
					onClick={() => {
						setCurrentImage(currentImage + 1);
					}}
				>
					<RightArrow
						fill={imageTransitioning ? "gray" : arrowColour}
						height={64}
					/>
				</button>
			</div>
			<div
				className="fb-page-number"
				style={{
					opacity: imageTransitioning ? "0" : "1",
				}}
			>
				{currentImage + 1} of {images.length}
			</div>
		</div>
	);
};

export default Flipbook;