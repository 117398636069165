import React from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Index from "./Pages/Index";
import Navbar from "./Components/Navbar";
import Credits from "./Pages/Credits";
import JoinUs from "./Pages/JoinUs";
import LetterFromTheEditor from "./Pages/LetterFromTheEditor";
import Footer from "./Components/Footer";
import Article from "./Pages/Article";
import { FooterContext } from "./Contexts/FooterContext";
import ScrollToTop from "./Components/scrollIntoView";
import GreatGrandfatherCollage from "./Pages/CustomArticle/GreatGrandfatherCollage";
import PostcardsFromChildhood from "./Pages/CustomArticle/PostcardsFromChildhood";
import MagicMatt from "./Pages/CustomArticle/MagicMatt";
import SentimentalLanguage from "./Pages/CustomArticle/SentimentalLanguage";
import FutureYouFeared from "./Pages/CustomArticle/FutureYouFeared";

const getArticleColour = (articleType: string): string => {
	const colours: { [key: string]: string } = {
		JOURNEY: "#D7C2D2",
		ORIGIN: "#E1BCAE",
		SHIFT: "#FDC69E",
	};

	if (articleType in colours) {
		return colours[articleType];
	}

	return "white";
};

const getDisplayValue = (articleType: string): string => {
	if (articleType === "INDEX") return "none";
	return "flex";
};

function App() {
	const [articleType, setArticleType] = React.useState("");

	return (
		<BrowserRouter>
			<FooterContext.Provider value={{ articleType, setArticleType }}>
				<Navbar pageType={articleType} />
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<Index />} />
					<Route path="/credits" element={<Credits />} />
					<Route path="/joinus" element={<JoinUs />} />
					<Route path="/letter" element={<LetterFromTheEditor />} />

					{/* Custom articles */}
					<Route
						path="/article/tracing-my-family-tree"
						element={<GreatGrandfatherCollage />}
					/>
					<Route
						path="/article/postcards-from-childhood"
						element={<PostcardsFromChildhood />}
					/>
					<Route
						path="/article/a-day-with-tiktoks-magic-matt"
						element={<MagicMatt />}
					/>
					<Route
						path="/article/sentimental-language-of-math"
						element={<SentimentalLanguage />}
					/>
					<Route
						path="/article/the-future-you-feared"
						element={<FutureYouFeared />}
					/>

					{/* Standard wireframe articles */}
					<Route path="/article">
						<Route path=":id" element={<Article />} />
					</Route>
				</Routes>
				<Footer
					backgroundColor={getArticleColour(articleType)}
					display={getDisplayValue(articleType)}
				/>
			</FooterContext.Provider>
		</BrowserRouter>
	);
}

export default App;
