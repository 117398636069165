import React from "react";
import "../Styles/Navbar.css";
import Origin from "../Assets/origin.svg";
import Shift from "../Assets/shift.svg";
import Journey from "../Assets/journey.svg";

import OriginBW from "../Assets/origin-bw.svg";
import ShiftBW from "../Assets/shift-bw.svg";
import JourneyBW from "../Assets/journey-bw.svg";

import Logo from "../Assets/logo.svg";
import Search from "../Assets/search.svg";
import Close from "../Assets/close_icon.svg";
import { Link } from "react-router-dom";

type NavbarProps = {
	pageType: string;
};

type SearchResult = {
	name: string;
	path: string;
	similarity: number;
};

function Navbar({ pageType }: NavbarProps) {
	const [searchQuery, setSearchQuery] = React.useState("");
	const [searchResults, setSearchResults] = React.useState<SearchResult[]>([]);
	const [showSearchModal, setShowSearchModal] = React.useState(false);

	// Temporary place for page links
	const pages = [
		{
			name: "Home",
			path: "/",
		},
		{
			name: "Credits",
			path: "/credits",
		},
		{
			name: "Letter from the editor",
			path: "/letter",
		},
		{
			name: "Join us",
			path: "/joinus",
		},
		{
			name: "Mooncake melancholy",
			path: "/article/mooncakes",
		},
		{
			name: "What do we really remember?",
			path: "/article/accuracy-of-memory",
		},
		{
			name: "We could all learn something from Neon Genesis Evangelion",
			path: "/article/neon-genesis-evangelion",
		},
		{
			name: "My grandmother’s story taught me that life is sweeter with a teaspoon of sugar",
			path: "/article/grandma-legacy",
		},
		{
			name: "All the time in the world",
			path: "/article/all-the-time-in-the-world",
		},
		{
			name: "Language in hindsight",
			path: "/article/language-in-hindsight",
		},
		{
			name: "Tracing my family tree",
			path: "/article/tracing-my-family-tree",
		},
		{
			name: "Postcards from childhood",
			path: "/article/postcards-from-childhood",
		},
		{
			name: "Fandom is older than you think",
			path: "/article/history-of-fandom",
		},
		{
			name: "Where does life come from?",
			path: "/article/origins-of-life",
		},
		{
			name: "“A damned mess”: Reflections from working at a late-night poutine shop",
			path: "/article/poutine-shop",
		},
		{
			name: "Tales from the closet",
			path: "/article/tales-from-the-closet",
		},
		{
			name: "A day with Tiktok’s Magic Matt",
			path: "/article/a-day-with-tiktoks-magic-matt",
		},
		{
			name: "The sentimental language of mathematics",
			path: "/article/sentimental-language-of-math",
		},
		{
			name: "The future you feared",
			path: "/article/the-future-you-feared",
		},
		{
			name: "Nostalgia just isn’t what it used to be — it’s worse",
			path: "/article/nostalgia-isnt-what-it-used-to-be",
		},
		{
			name: "Sex in the locked-down city",
			path: "/article/sex-in-the-locked-down-city",
		},
		{
			name: "Longing for Shanghai",
			path: "/article/longing-for-shanghai",
		},
		{
			name: "In the aftermath",
			path: "/article/aftermaths",
		},
	];

	// Temporary search function
	const compareSimilarity = (a: String, b: String) => {
		const aLower = a.toLowerCase();
		const bLower = b.toLowerCase();
		/* Compute Levenshtein distance */
		const distance = levenshtein(aLower, bLower);
		/* Compute similarity (1 - (distance / length)) */
		const similarity = 1 - distance / Math.max(aLower.length, bLower.length);
		return similarity;
	};

	// https://en.wikipedia.org/wiki/Levenshtein_distance
	// We use this to compute the similarity between the search query and the page name
	// The higher the similarity, the more similar the page is to the search query
	const levenshtein = (a: String, b: String) => {
		if (a.length === 0) return b.length;
		if (b.length === 0) return a.length;

		const matrix = [];

		// increment along the first column of each row
		let i;
		for (i = 0; i <= b.length; i++) {
			matrix[i] = [i];
		}

		// increment each column in the first row
		let j;
		for (j = 0; j <= a.length; j++) {
			matrix[0][j] = j;
		}

		// Fill in the rest of the matrix
		for (i = 1; i <= b.length; i++) {
			for (j = 1; j <= a.length; j++) {
				if (b.charAt(i - 1) === a.charAt(j - 1)) {
					matrix[i][j] = matrix[i - 1][j - 1];
				} else {
					matrix[i][j] = Math.min(
						matrix[i - 1][j - 1] + 1, // substitution
						Math.min(
							matrix[i][j - 1] + 1, // insertion
							matrix[i - 1][j] + 1
						) // deletion
					);
				}
			}
		}

		return matrix[b.length][a.length];
	};

	const getColouredIcons = (articleType : string) => {
		const enabledIcons = [
			false, false, false
		];

		if (articleType === "ORIGIN") {
			enabledIcons[0] = true;
		} else if (articleType === "JOURNEY") {
			enabledIcons[1] = true;
		} else if (articleType === "SHIFT") {
			enabledIcons[2] = true;
		} else {
			enabledIcons[0] = true;
			enabledIcons[1] = true;
			enabledIcons[2] = true;
		}

		return enabledIcons;
	}

	// Search function
	const runSearch = () => {
		if (searchQuery.length > 0) {
			let results: SearchResult[] = [];
			pages.forEach((page) => {
				let similarity = compareSimilarity(page.name, searchQuery);
				if (page.name.toLowerCase().split(" ").includes(searchQuery.toLowerCase())) {
					similarity = Math.min(similarity * 1.2, 1); // Boost exact matches
				}
				if (similarity > 0) {
					results.push({
						name: page.name,
						path: page.path,
						similarity,
					});
				}
			});
			results.sort((a, b) => b.similarity - a.similarity);
			let topResults: SearchResult[] = [];
			let resultCount = 0;

			for (let i = 0; i < results.length; i++) {
				// We want to convert the similarity to a percentage
				results[i].similarity = Math.floor(
					Math.round(results[i].similarity * 100)
				);
				if (results[i].similarity > 10 && resultCount < 5) {
					topResults.push(results[i]);
					resultCount++;
				}
			}
			setSearchResults(topResults);
		}
	};

	return (
		<div>
			{/* Search modal for mobile */}
			<div
				className="search-modal-mobile"
				style={{
					opacity: showSearchModal ? 1 : 0,
					visibility: showSearchModal ? "visible" : "hidden",
				}}
			>
				<div className="search-mobile-header">
					<h1
						style={{
							paddingLeft: "20px",
						}}
					>
						Search
					</h1>
					<div className="search-modal-input-container">
						<input
							className="search-input-mobile"
							type="text"
							value={searchQuery}
							onClick={(e) => {
								setSearchQuery("");
								setSearchResults([]);
							}}
							onChange={(e) => {
								setSearchQuery(e.target.value);
								runSearch();
							}}
							onKeyDown={(e) => {
								setSearchQuery(e.currentTarget.value);
								runSearch();
							}}
						/>
					</div>
				</div>
				<hr />
				<div className="search-modal-results-container">
					{searchResults.map((result) => (
						<Link
							key={result.name + result.path + result.similarity}
							to={result.path}
							onClick={(e) => {
								setShowSearchModal(false);
								setSearchQuery("");
								setSearchResults([]);
							}}
							className="search-result-link-mobile"
						>
							<div className="search-modal-result">
								<p
									style={{
										margin: "0",
									}}
								>
									{result.name}
								</p>
							</div>
						</Link>
					))}
				</div>
			</div>
			{/* Navbar */}
			<nav className="custom-nav">
				<div className="nav-wrapper">
					<div>
						<Link to="/">
							<img src={Logo} alt="HINDSIGHT" className="nav-img" />
						</Link>
					</div>
					<div>
						<Link to="/#origin">
							<img src={getColouredIcons(pageType)[0] ? Origin : OriginBW} alt="Origin" className="nav-img" />
						</Link>
					</div>
					<div>
						<Link to="/#journey">
							<img src={getColouredIcons(pageType)[1] ? Journey : JourneyBW} alt="Journey" className="nav-img" />
						</Link>
					</div>
					<div>
						<Link to="/#shift">
							<img src={getColouredIcons(pageType)[2] ? Shift : ShiftBW} alt="Shift" className="nav-img" />
						</Link>
					</div>
					<div>
						<input
							type="text"
							className="search-input"
							placeholder="Search"
							onKeyUp={(e) => {
								setSearchQuery(e.currentTarget.value);
								runSearch();
							}}
							onChange={(e) => {
								setSearchQuery(e.currentTarget.value);
								runSearch();
							}}
							onClick={(e) => {
								setSearchQuery("");
								setSearchResults([]);
							}}
							value={searchQuery}
						/>

						<img
							src={showSearchModal ? Close : Search}
							alt="Mobile Search Widget"
							className="search-img"
							style={{
								width: "32px",
							}}
							onClick={(e) => {
								setShowSearchModal(!showSearchModal);
								// Clear the search query
								setSearchQuery("");
								setSearchResults([]);
							}}
						/>
						<div className="search-results-desktop">
							{searchResults.map((result) => (
								<div className="search-result-desktop">
									<Link
										className="search-result-link"
										onClick={(e) => {
											setSearchQuery("");
											setSearchResults([]);
										}}
										to={result.path}
									>
										{result.name} ({result.similarity}%)
									</Link>
								</div>
							))}
						</div>
					</div>
				</div>
			</nav>
			<div className="nav-bottom"></div>
		</div>
	);
}

export default Navbar;
