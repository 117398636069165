import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ReactMarkdown from "react-markdown";
import "../Styles/Article.css";
import Logo from "../Assets/footer-logo.svg";
import { useFooterContext } from "../Contexts/FooterContext";
import rehypeRaw from "rehype-raw";
import isMobile from "../Hooks/isMobile";

type ArticleType = {
	title: string;
	hed: string;
	dek: string;
	featured_image?: string;
	featured_video?: string;
	mobile_res?: string;
	body: string;
	articleType: string;
	byLine: string;
	hide_feature?: boolean;
};

const Article = () => {
	const params = useParams();

	const footerContext = useFooterContext();

	const [article, setArticle] = useState<ArticleType>({
		title: "",
		hed: "",
		dek: "",
		featured_image: "",
		featured_video: "",
		mobile_res: "",
		body: "",
		articleType: "",
		byLine: "",
		hide_feature: undefined,
	});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		try {
			const data = require(`../Articles/${params.id}.json`);
			const body_url = require(`../Articles/${params.id}.md`).default;
			setTimeout(
				() =>
					fetch(body_url)
						.then((res) => res.text())
						.then((body) => {
							setArticle({
								title: data.title,
								hed: data.hed,
								dek: data.dek,
								featured_video: data.featured_video,
								featured_image: data.featured_image,
								mobile_res: data.mobile_res,
								body: body,
								articleType: data.type || "",

								byLine: data.byline,
								hide_feature: data.hide_feature || false,
							});
							document.title = data.title;
							footerContext.setArticleType(data.type || "");
						})
						.catch((err) => {
							console.error(err);
							setArticle({
								title: "500 Internal Server Error",
								hed: "",
								dek: "",
								featured_image: "",
								featured_video: "",
								mobile_res: "",
								body: "",
								articleType: "",
								byLine: "",
								hide_feature: false,
							});
							footerContext.setArticleType("");
						})
						.finally(() => setTimeout(() => setLoading(false), 1000)),
				500
			);
		} catch (e) {
			console.log(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	const isMobileView = isMobile();

	return (
		<>
			<div
				style={{
					position: "fixed",
					top: 0,
					width: "100%",
					height: "100%",
					left: 0,
					right: 0,
					bottom: 0,
					opacity: loading ? 1 : 0,
					backgroundColor: "rgba(252,252,252)",
					transition: "all 0.5s ease-out",
					zIndex: 100,
					visibility: loading ? "visible" : "hidden",
				}}
			>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						textAlign: "center",
						fontSize: "2em",
						fontWeight: "bold",
						width: "100%",
						height: "100%",
					}}
				>
					<img src={Logo} alt="loading" />
				</div>
			</div>
			<div>
				{article.featured_image && !article.hide_feature && (
					<div
						style={{
							background: article.featured_image
								? `url(${isMobileView && article.mobile_res ? article.mobile_res : article.featured_image})`
								: "white",
							backgroundSize: article.featured_image ? "cover" : "contain",
							backgroundPosition: article.featured_image ? "center" : "",
							height: "70vh",
							width: "100%",
							marginBottom: "20px",
						}}
					/>
				)}
				{article.featured_video && !article.hide_feature && (
					<div
						style={{
							backgroundColor: "black",
							height: "100%",
							width: "100%",
						}}
					>
						<video
							autoPlay={true}
							muted={true}
							loop={true}
							style={{ width: "100%", height: isMobileView ? "80vh" : "70vh", marginBottom: "-5px" }}
						>
							{article.mobile_res && isMobileView && (
								<source src={article.mobile_res} type="video/mp4" />
							)}
							<source src={article.featured_video} type="video/mp4" />
						</video>
					</div>
				)}

				<div
					className="article-body"
					style={{
						paddingBottom: "40px",
					}}
				>
					{!article.hide_feature && (
						<div className="header">
							<div
								style={{
									paddingBottom: "20px",
									transition: "all 0.2s ease-in-out",
								}}
							>
								<ReactMarkdown children={"# " + article.hed} />
							</div>
							{article.dek && (
								<h3>
									<ReactMarkdown children={article.dek} />
								</h3>
							)}
							{article.byLine && (
								<h5 style={{ paddingTop: 10, paddingBottom: 20 }}>By {article.byLine}</h5>
							)}
						</div>
					)}
					<ReactMarkdown children={article.body} rehypePlugins={[rehypeRaw]} />
				</div>
			</div>
			<style>
				{`
            html {
                overflow-y: ${loading ? "hidden" : "scroll"};
            }
        `}
			</style>
		</>
	);
};

export default Article;
