import React, { useEffect } from "react";
import { useFooterContext } from "../Contexts/FooterContext";
import "../Styles/JoinUs.css";

const JoinUs = () => {
	const footerContext = useFooterContext();
	useEffect(() => {
		footerContext.setArticleType("");
		document.title = "Join us";
	}, [footerContext]);

	return (
		<div className="joinus-bg">
			<div className="container">
				<div className="joinus">
					<h1 className="header">JOIN US</h1>
					<h4 className="join-us-text">
						Are you worried about spending the whole year in your dorm room?
						<br />
						<br />
						We were too. <br />
						<br />
						Don’t get us wrong, staying inside comes with many perks. We know
						them well here at The Varsity. But if we were to describe our team,
						we’d say we’re a bunch of people who are more accustomed to
						binge-watching TV in bed — and one day, we decided to hit pause and
						try our hand at something new. Sure, there are some of us here who
						have a natural high-energy, go-getter nature, but we don’t mind if
						you don’t. <br />
						<br />
						And, seriously, we want you to work with us. <br />
						<br />
						We’ve been covering the news at U of T for almost 150 years, and you
						can be a part of this legacy. Whether you’re a writer, artist,
						photographer, or video editor, there's always a way to get involved.
						No experience necessary; we’ll teach you the ropes. We’ve all been
						exactly where you are: both excited and nervous about all the
						opportunities available to you. We publish our paper because we care
						deeply about our community — and that includes you. So if
						contributing to The Varsity sounds even mildly interesting to you,
						give it a try! You can sign up to join our team{" "}
						<a href="https://volunteer.thevarsity.ca/">here.</a> <br />
						<br />
						Hope to see you around! <br />
						<br />— The Varsity’s Masthead, Volume 142{" "}
					</h4>
				</div>
			</div>
		</div>
	);
};

export default JoinUs;
