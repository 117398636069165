import React, { useEffect } from "react";
import { useFooterContext } from "../Contexts/FooterContext";
import Loading from "../Assets/loading.gif";
import { useLocation } from "react-router-dom";

enum PageType {
	NONE,
	ORIGIN,
	JOURNEY,
	SHIFT,
}

const getSectionNumber = (section: PageType) => {
	const sectionNumber = {
		[PageType.NONE]: "",
		[PageType.ORIGIN]: "origin",
		[PageType.JOURNEY]: "journey",
		[PageType.SHIFT]: "shift",
	};

	return sectionNumber[section];
};

const Index = () => {
	const footerContext = useFooterContext();
	const [isLoading, setIsLoading] = React.useState(true);
	const [loadingOverlayOpacity, setLoadingOverlayOpacity] = React.useState(1);
	const location = useLocation();
	const [section, setSection] = React.useState(PageType.NONE);

	useEffect(() => {
		footerContext.setArticleType("INDEX");
		document.title = "HINDSIGHT";
		document.body.style.overflowY = "hidden";
		document.body.style.height = "100vh";

		if (window.location.hash.substring(1) !== undefined) {
			switch (window.location.hash.substring(1)) {
				case "origin":
					setSection(PageType.ORIGIN);
					break;
				case "journey":
					setSection(PageType.JOURNEY);
					break;
				case "shift":
					setSection(PageType.SHIFT);
					break;
				default:
					setSection(PageType.NONE);
					break;
			}
		}
	}, [footerContext, location.hash]);

	const setLoaded = () => {
		setTimeout(() => {
			setLoadingOverlayOpacity(0);
			setTimeout(() => {
				setIsLoading(false);
			}, 500);
		}, 1000);
	};

	return (
		<>
			<div
				style={{
					display: isLoading ? "flex" : "none",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "calc(100vh - 80px)",
					background:
						"linear-gradient(0deg, rgba(130, 46, 69, 1) 0%, rgba(253, 198, 158, 1) 100%)",
					opacity: loadingOverlayOpacity,
					transition: "opacity 0.5s ease-in-out",
				}}
			>
				<div
					style={{
						background: "white",
						width: "180px",
						height: "180px",
						borderRadius: "10px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<img
						src={Loading}
						alt="Loading"
						style={{
							width: "128px",
							height: "128px",
						}}
					/>
				</div>
			</div>
			<iframe
				style={{
					border: "none",
					height: "0",
					width: "100%",
					margin: "0px",
					padding: "0px",
					opacity: isLoading ? 0 : 1,
					transition: "opacity 0.5s ease-in-out",
				}}
				title="Front page"
				onLoad={(event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
					event.currentTarget.style.height = "calc(100vh - 80px)";
					setLoaded();
				}}
				src={`/frontpage.html${
					section === PageType.NONE ? "" : `#${getSectionNumber(section)}`
				}`}
			/>
		</>
	);
};

export default Index;
