import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../Styles/Article.css";
import "../../Styles/SentimentalLanguage.css";
import Logo from "../../Assets/footer-logo.svg";
import { useFooterContext } from "../../Contexts/FooterContext";

type ArticleType = {
	title: string;
	hed: string;
	dek: string;
	featured_image?: string;
	body: string;
	articleType: string;
	byLine: string;
};

const SentimentalLanguage = () => {
	const params = useParams();

	const footerContext = useFooterContext();

	const [article, setArticle] = useState<ArticleType>({
		title: "",
		hed: "",
		dek: "",
		featured_image: "",
		body: "",
		articleType: "",
		byLine: "",
	});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		try {
			const data = require(`../../Articles/sentimental-language-of-math.json`);

			setTimeout(() => {
				setArticle({
					title: data.title,
					hed: data.hed,
					dek: data.dek,
					featured_image: data.featured_image,
					body: "",
					articleType: data.type || "",
					byLine: data.byline,
				});
				document.title = data.title;
				footerContext.setArticleType(data.type || "");
				setLoading(false);
			}, 1000);
		} catch (e) {
			console.log(e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	return (
		<>
			<div
				style={{
					position: "fixed",
					top: 0,
					width: "100%",
					height: "100%",
					left: 0,
					right: 0,
					bottom: 0,
					opacity: loading ? 1 : 0,
					backgroundColor: "rgba(252,252,252)",
					transition: "all 0.5s ease-out",
					zIndex: 100,
					visibility: loading ? "visible" : "hidden",
				}}
			>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						textAlign: "center",
						fontSize: "2em",
						fontWeight: "bold",
						width: "100%",
						height: "100%",
					}}
				>
					<img src={Logo} alt="loading" />
				</div>
			</div>
			<div>
				<div
					style={{
						background: article.featured_image
							? `url(${article.featured_image})`
							: "white",
						backgroundSize: article.featured_image ? "cover" : "contain",
						backgroundPosition: article.featured_image ? "center" : "",
						height: "110vh",
						width: "100%",
					}}
				/>
				<div className="math-container">
					<div className="math-body">
						<p
							style={{
								textAlign: "center",
								fontSize: "1.5em",
							}}
						>
							By {article.byLine}
						</p>
					</div>
					<div className="math-body">
						<p>
							From a timid girl to a young woman, the transformation that I
							underwent in the past decade could be thought of as a
							metamorphosis.
						</p>
						<p>
							This period of my life has featured many clashes between
							romanticism and reality, especially when I was confronted with the
							challenge of adapting to a foreign culture while dealing with
							mental health struggles. During that time, I promised myself that
							I would someday write about my times of hardship.
						</p>
						<p>
							This reflection is dedicated to the documentation of the setbacks
							I overcame, but more importantly, I hope it can inspire awareness
							for those who have not yet found the courage to speak out about
							their own struggles.
						</p>
					</div>
					<div className="math-body">
						<img
							className="math-subheader"
							src="https://hindsight-cdn.thevarsity.ca/hindsight/sentimental-language-math-subheader-1.journey.png"
							alt="math"
						/>
						<p>
							The first major turning point in my monotonous life came when my
							parents broke the news that my family was moving to Canada.{" "}
						</p>
						<p>
							I was 11. I literally envisioned myself living in an igloo,
							drinking maple syrup, and going to places in a husky-pulled sled.
							Leaving everything I knew in Taiwan was daunting in and of itself,
							not to mention the anxiety I felt about setting foot onto an
							unknown realm. All of a sudden, I had to start my life over from
							scratch.
						</p>
						<p>
							My new life in Canada felt like a never-ending English class, due
							to the fact that I did not speak a word of English. At the same
							time, though, I found the universal language of mathematics to be
							very comforting. What once was a loathed school subject became a
							means of self-expression. I even treated weekly elementary school
							math journal exercises as my personal journal.{" "}
						</p>
						<p>
							Learning a new language was as daunting as learning a new way of
							living. I wanted to belong, to be an accepted member among my
							classmates, but somehow, I turned out to be inherently different.
							I was shocked to see people wearing flip-flops to school in the
							summer. Most popular school fundraising dances were horrifying,
							given that I was never physically coordinated enough to dance.{" "}
						</p>
						<p>
							Gradually, reading became a safe haven amid the challenges I faced
							in adjusting to a Western style of living. My rising grades on
							report cards were directly proportional to the increasing
							name-calling directed at me. I became widely referred to as the
							‘Asian geek’ or ‘bookworm.’ I felt lost, confused, and doubtful
							about who I was supposed to be.
						</p>
					</div>
					<div className="math-body">
						<img
							className="math-subheader"
							src="https://hindsight-cdn.thevarsity.ca/hindsight/sentimental-language-math-subheader-2.journey.png"
							alt="math"
						/>
						<p>
							If you were to ask me to choose one mathematical function to graph
							my tumultuous teenage years, I would undoubtedly choose the
							sinusoidal function of f(x) = cos(x).
						</p>
						<p>
							After a year and a half of major depression, I received an
							official diagnosis of bipolar disorder. I underwent violent mood
							swings — sometimes I was overly excited, and other times I felt a
							deep melancholy, like the peaks and troughs of the graph of a
							periodic function. Given my poor attendance record, I began to
							develop an on-and-off relationship with school.
						</p>
						<p>
							Mathematics, which was once my source of comfort, turned into a
							nightmare. Due to the instability of my cognitive functioning, I
							almost failed grade 10 math. My grades, at that point, were
							inversely proportional to my rapid weight gain. I began to fall
							behind academically, and this took a huge toll on my self-esteem.
						</p>
						<p>
							I know not where the determination to redeem myself originated,
							but I decided to retake grade 10 math twice. I refused to move on
							until I truly grasped the essential concepts.
						</p>
						<p>
							But as I was struggling with my mental health, many of my friends
							drifted away. I felt like an outcast. Everyone referred to me as
							“psycho” in school, and I even recall one incident in which
							someone splashed their pen ink all over my locker.
						</p>
					</div>
					<div className="math-body">
						<img
							className="math-subheader"
							src="https://hindsight-cdn.thevarsity.ca/hindsight/sentimental-language-math-subheader-3.journey.png"
							alt="math"
						/>
						<p>
							By mathematical definition, a prime number is only divisible by 1
							and itself. I can relate to that: I spent my 19th birthday alone
							in the hospital because of an acute mania episode. Somehow, I
							reverted back to communicating to the world via the language of
							math and science.
						</p>
						<p>
							However, my disorganized logics and principles were only
							applicable in my own abstract dimension, incompatible to the
							physical laws of the universe. My disorderly mind caused quite a
							lot of havoc in my personal relationships.
						</p>
						<p>
							Recently, when I examined some of the journal entries preserved
							from the three-month-long hospital stay, fragments of interesting
							memories flashed through my mind. There were numerous records of
							the novel ideas I came up with during that time, such as a
							timepiece made of soap and warm water that could measure the
							circumference of the cross-section of Earth based on the angle of
							sunlight, and the blueprint for an intercontinental train track. I
							had “new scientific experiments and discoveries” every day,
							according to my parents’ observations.
						</p>
						<p>
							Even after I was discharged, I suffered from unprecedented
							symptoms of social anxiety. Everyone seemed to be moving on to
							bigger and better things in life, while I enrolled in an adult
							learning centre to complete my secondary education. I longed to be
							understood and accepted.
						</p>
						<p>
							But days of hardships and setbacks eventually paid off, and I am
							very grateful for the opportunity that U of T has offered me to
							further my educational endeavours.
						</p>
						<p>
							To be honest, I still fluctuate between good and bad days. I would
							be lying if I said that my situation is stable. But despite having
							to battle with bipolar disorder for the rest of my life, I still
							believe that all of my small bits of progress will add up to a
							great sum.
						</p>
					</div>
					<div className="math-body">
						<img
							className="math-subheader"
							src="https://hindsight-cdn.thevarsity.ca/hindsight/sentimental-language-math-subheader-4.journey.png"
							alt="math"
						/>
						<p>
							The greatest challenge of living with a mental health disorder is
							drawing a clear boundary between my symptoms and who I am as a
							person — but after years of self-doubt, I’ve slowly realized that
							I am much more than my diagnosis.
						</p>
						<p>
							If I could travel to the past, I would advise my younger self to
							always hold onto my Taiwanese roots, and be on the lookout for new
							ground to grow from. Do not belittle roots, as they are
							indispensable to the growth of any plant. I would not have the
							strength to stand my ground without such robust support stemming
							from traditional Taiwanese values.
						</p>
						<p>
							My homeland, Taiwan, is known to be shaped like a sweet potato.
							From a young age, my father taught me to be as humble and
							adaptable as the root vegetable, even under harsh conditions. My
							unique life history has allowed me to develop deeper understanding
							and resilience, and I will never forget the enthusiasm and
							optimistic spirit of my native land.
						</p>
						<p>
							I hope my story can shed some light on the intersectionality of
							immigration and coping with mental health issues. I would like to
							express my heartfelt gratitude to friends, family, and mentors who
							believed in my potential when I was in genuine doubt, and I want
							to pledge to offer support and comfort to whomever I can reach to
							the best of my ability.
						</p>
						<p>
							I do not know whether I will accomplish great things. All I know
							is that I am determined to promote understanding and awareness for
							causes dear to my heart. One match is sufficient to spark the
							night sky with splendid fireworks — and I want to try to be that
							match.
						</p>
						<br />
						<p>
							<em>
								Cover visual by Caroline Bellamy and Angel Hsieh, background
								visuals by Angel Hsieh.
							</em>
						</p>
					</div>
				</div>
			</div>
			<style>
				{`
            html {
                overflow-y: ${loading ? "hidden" : "scroll"};
            }
        `}
			</style>
		</>
	);
};

export default SentimentalLanguage;
